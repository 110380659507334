import React, { useState } from 'react';
import Layout from '../../../components/Layout';
import axios from 'axios';

const FAQ = () => {
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [subject, setSubject] = useState('');
  const [message, setMessage] = useState('');
  const [sent, setSent] = useState(false);
  const [error, setError] = useState(false);

  const onSend = async () => {
    try {
      const res = await axios.post(
        `https://us-central1-iconic-mariner-146115.cloudfunctions.net/linearsolutions-contact`,
        {
          name,
          email,
          subject,
          message,
        }
      );
      setSent(true);
    } catch (err) {
      console.log(err);
      setSent(true);
      setError(true);
    }
  };

  return (
    <Layout fullMenu logoUrl="/TX/engineering">
      <article id="main">
        <header>
          <h2>Frequently Asked Questions</h2>
        </header>
        <section className="wrapper style5">
          <div className="inner">
            <h4>How do I create an account?</h4>
            <p>
              You can use the “Sign In” option in the top right of the page to
              create an account, or sign into an existing account. You may also
              create an account after successfully passing a test.
            </p>
            <h4>When will I receive my certificate of completion?</h4>
            <p>
              Your certificate will be emailed to you instantly upon completion
              of the course
            </p>
            <h4>If I lost my certificate, how do I receive another copy?</h4>
            <p>
              Sign into your account, then go to your user dashboard. All
              course(s) that you have completed will be displayed and you can
              email yourself a copy whenever you’d like.
            </p>

            <h4>When do I have to complete my CE requirements by?</h4>
            <p>
              Texas Engineers must complete their CE licensure requirements
              annually. Your renewal date will be on one of the following days:
              March 31st, June 30th, September 30th, or December 31st
            </p>

            <h4>
              How many hours am I required to complete to renew my license?
            </h4>
            <p>
              Professional Engineers licensed in Texas must have 15 continuing
              education (CE) credits/hours by their annual renewal date.
            </p>

            <h4>What topic areas do I need to complete?</h4>
            <p>
              Of the 15 hours, a minimum of 1 credit/hour over the Act/Rules
              and/or Ethics must be taken every year and cannot be carried over.
              You may, however, carry over up to fourteen units of other
              approved CE from the previous year.
            </p>

            <h4>
              I completed course 1, but want to take course 2, how do I do this?
            </h4>
            <p>
              Go ahead and answer the test questions for course 2. Once you
              pass, the course will be added to your user dashboard. If you have
              already paid for course 1, you will be charged the difference
              ($20) for completing course 2.
            </p>

            <h4>Does AdvanCE offer CE for other states?</h4>
            <p>
              We’re currently offering continuing education for Professional
              Engineers in Mississippi, Texas, and Florida. We do however plan
              on entering additional markets in the future.
            </p>
          </div>
        </section>
      </article>
    </Layout>
  );
};

export default FAQ;
